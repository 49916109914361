import { DocumentTableState } from './document-table-state';
import {
  fetchDocumentTables,
  fetchDocumentTablesFailure,
  fetchDocumentTablesSuccess,
  refreshTables,
  restoreDocumentTable,
  restoreDocumentTableFailure,
  restoreDocumentTableProgressUpdate,
  restoreDocumentTableSuccess,
} from './document-table-state.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { createReducer, on } from '@ngrx/store';

export const adapter = createEntityAdapter<DocumentTable>({
  selectId: (table) => generateDocumentTableStateID(table.documentID, table.name),
});
export const initialState: DocumentTableState = {
  ...adapter.getInitialState(),
  fetchingState: {},
  restoringState: {},
  documentSequencesCount: {},
};

export const documentTableStateReducer = createReducer(
  initialState,
  on(refreshTables, (state, { documentID }) => ({
    ...state,
    fetchingState: { [documentID]: { fetching: true } },
  })),
  on(fetchDocumentTables, (state, { documentID, numberOfSequences }) => ({
    ...state,
    fetchingState: { [documentID]: { fetching: true } },
    documentSequencesCount: {
      ...state.documentSequencesCount,
      [documentID]: numberOfSequences,
    },
  })),
  on(fetchDocumentTablesSuccess, (state, { documentID, tables }) => ({
    ...adapter.setMany(
      tables,
      adapter.removeMany((entity) => entity.documentID.startsWith(documentID), state),
    ),
    fetchingState: { [documentID]: { fetching: false } },
  })),
  on(fetchDocumentTablesFailure, (state, { documentID, reason }) => ({
    ...state,
    fetchingState: { [documentID]: { fetching: false, error: reason } },
  })),
  on(restoreDocumentTable, (state, { documentID, tableName }) => ({
    ...state,
    restoringState: {
      ...state.restoringState,
      [generateDocumentTableStateID(documentID, tableName)]: {
        restoring: true,
        restored: false,
        progress: 0,
      },
    },
  })),
  on(restoreDocumentTableProgressUpdate, (state, { documentID, tableName, progress }) => ({
    ...state,
    restoringState: {
      ...state.restoringState,
      [generateDocumentTableStateID(documentID, tableName)]: {
        restoring: true,
        restored: false,
        progress,
      },
    },
  })),
  on(restoreDocumentTableSuccess, (state, { documentID, tableName }) => ({
    ...state,
    ...adapter.updateOne(
      {
        id: generateDocumentTableStateID(documentID, tableName),
        changes: { indexState: 'open' },
      },
      state,
    ),
    restoringState: {
      ...state.restoringState,
      [generateDocumentTableStateID(documentID, tableName)]: {
        restoring: false,
        restored: true,
        progress: 100,
      },
    },
  })),
  on(restoreDocumentTableFailure, (state, { documentID, tableName, error }) => ({
    ...state,
    restoringState: {
      ...state.restoringState,
      [generateDocumentTableStateID(documentID, tableName)]: {
        restoring: false,
        restored: false,
        error,
      },
    },
  })),
);

export function generateDocumentTableStateID(documentID: string, tableName: string) {
  return documentID + tableName;
}

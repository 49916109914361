import { createAction, props } from '@ngrx/store';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { FetchingStateError, RestoreStateError } from './document-table-state';

export const refreshTables = createAction(
  '[Document Table State] Refresh Tables',
  props<{ documentID: string }>(),
);

export const fetchDocumentTables = createAction(
  '[Document Table State] Fetch Document Tables',
  props<{ documentID: string; numberOfSequences: number }>(),
);

export const fetchDocumentTablesInBulk = createAction(
  '[Document Table State] Fetch Document Tables in bulk',
  props<{ documentIDs: string[] }>(),
);

export const fetchDocumentTablesSuccess = createAction(
  '[Document Table State] Fetch Document Tables Success',
  props<{ documentID: string; tables: DocumentTable[] }>(),
);

export const fetchDocumentTablesFailure = createAction(
  '[Document Table State] Fetch Document Tables Failure',
  props<{ documentID: string; reason: FetchingStateError }>(),
);

export const documentTableSelected = createAction(
  '[Document Table State] Document Table Selected',
  props<{ documentID: string; tableName: string }>(),
);

export const restoreDocumentTable = createAction(
  '[Document Table State] Restore Document Table',
  props<{ documentID: string; tableName: string }>(),
);

export const restoreDocumentTableProgressUpdate = createAction(
  '[Document Table State] Restore Document Table Progress Update',
  props<{ documentID: string; tableName: string; progress: number }>(),
);

export const restoreDocumentTableSuccess = createAction(
  '[Document Table State] Restore Document Table Success',
  props<{ documentID: string; tableName: string }>(),
);

export const restoreDocumentTableFailure = createAction(
  '[Document Table State] Restore Document Table Failure',
  props<{ documentID: string; tableName: string; error: RestoreStateError }>(),
);

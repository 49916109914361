import { DocumentTable } from '../../../nucleus/services/documentService/types';
import { DocumentTableType } from '../../../nucleus/services/documentService/document-table-type';

/**
 * An object containing an entry of type: `{ tableType: DocumentTableType }`.
 * Allows these filters to be used by objects other than {@link DocumentTable}.
 */
type TableTypeObject = Pick<DocumentTable, 'tableType' | 'metadata' | 'columns'>;

/**
 * Filter only the valid tables that should be displayed to the user.
 * e.g. They shouldn't see the Codon usage tables.
 */
export function validTablesForDisplay<T extends TableTypeObject>(tables: T[]): T[] {
  return tables.filter((table) => {
    return (
      isAllSequencesTable(table) ||
      isChainCombinationsTable(table) ||
      isClusterTable(table) ||
      isGeneClusterTable(table) ||
      isCherryPickingTable(table) ||
      isComparisonSummaryTable(table) ||
      isComparisonClusterTable(table) ||
      isComparisonGeneClusterTable(table) ||
      isComparisonClusterLengthTable(table) ||
      isMasterDatabaseTable(table)
    );
  });
}

/**
 * An "All Sequences" table typically represents all the sequences in a data set, including
 * sequences that annotation failed. Each row in the table currently represents either a single
 * sequence, or a pair of sequences (such as heavy and light chains).
 */
export function isAllSequencesTable(table: TableTypeObject): boolean {
  return (
    table.metadata?.tableContentType === 'AllSequences' ||
    table.tableType === DocumentTableType.SEQUENCES
  );
}

export function isExactClusterTable(table: TableTypeObject): boolean {
  if (table.tableType === DocumentTableType.CLUSTER_GENE) {
    const isPureGeneClusterTable = table.metadata?.clusters?.clusterGroups.every((clusterGroup) =>
      clusterGroup.regionOrGenes.every((regionOrGene) => regionOrGene.dataType === 'GeneNames'),
    );
    return !isPureGeneClusterTable;
  }
  return table.tableType === DocumentTableType.CLUSTERS;
}

export function isTemplateMatchesTable(table: DocumentTable): boolean {
  return (
    isExactClusterTable(table) &&
    table.metadata?.clusters?.clusterGroups.some((group) => group.clusterMethod === 'TemplateName')
  );
}

export function isChainCombinationsTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.ANNOTATOR_RESULT_CHAIN_COMBINATIONS;
}

/**
 * Similarity cluster tables represent the variation present in the dataset within a particular
 * sequence region. Each row represents a related family of protein sequences in that region. Each
 * row may represent one or more identity clusters, and thus one or more individual whole
 * sequences.
 */
export function isInexactClusterTable(table: TableTypeObject): boolean {
  return (
    table.metadata?.clusters?.containsAnyInexactClusters ||
    table.tableType === DocumentTableType.INEXACT_CLUSTER ||
    table.columns.some((column) => column.name.startsWith('Cluster Contents'))
  );
}

/**
 * Gene cluster tables represent which genes sequences in the dataset are most similiar to. Genes
 * are defined in the reference database. Each row represents a single gene, and one or more
 * individual whole sequences.
 */
export function isGeneClusterTable(table: TableTypeObject): boolean {
  return (
    table.tableType === DocumentTableType.CLUSTER_GENE ||
    table.metadata?.clusters?.clusterGroups.every((clusterGroup) =>
      clusterGroup.regionOrGenes.every((regionOrGene) => regionOrGene.dataType === 'GeneNames'),
    )
  );
}

export function isCherryPickingTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.CHERRY_PICKING;
}

/**
 * NOTE: This is for Annotated Results, not Comparison cluster tables. For Comparisons use
 * `isComparisonClusterTable()`. There are various types of cluster tables, but they all typically
 * represent a collapsed view of the sequences in a data set, based around a sequence region or
 * gene. They often do not include sequences where annotation failed. Each row in the table
 * represents one or more sequences.
 */
export function isClusterTable(table: TableTypeObject): boolean {
  return (
    table.tableType === DocumentTableType.CLUSTERS ||
    table.tableType === DocumentTableType.CLUSTER_GENE ||
    table.tableType === DocumentTableType.INEXACT_CLUSTER
  );
}

export function isComparisonTable(table: TableTypeObject): boolean {
  return (
    isComparisonSummaryTable(table) ||
    isComparisonClusterTable(table) ||
    isComparisonGeneClusterTable(table) ||
    isComparisonClusterLengthTable(table)
  );
}

export function isComparisonSummaryTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.COMPARISON_SUMMARY;
}

/**
 * Newly created comparison cluster tables will have associated sequences.
 */
export function isComparisonClusterTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.COMPARISON_CLUSTERS;
}

export function isComparisonGeneClusterTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.COMPARISON_CLUSTER_GENE;
}

export function isComparisonClusterLengthTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.COMPARISON_CLUSTER_LENGTH;
}

/**
 * Comparison tables are of four types: Summary tables, cluster regions, cluster gene, and cluster
 * region length tables. Currently only Comparison cluster region tables are supported for
 * alignment.
 */
export function isAlignmentSupportedComparisonTable(table: TableTypeObject): boolean {
  return isComparisonClusterTable(table);
}

/**
 * Newer Comparison results supports sequence viewer on Cluster and Length tables.
 * These newer comparison results contain a metadata property `supportsSequenceExtraction`
 * to indicate it supports Sequence Viewer.
 */
export function isComparisonTableThatSupportsSequenceViewer(table: TableTypeObject): boolean {
  return isComparisonClusterTable(table) || isComparisonClusterLengthTable(table);
}

export function isMasterDatabaseTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.MASTER_DATABASE;
}

export function isMasterDatabaseSearchResultTable(table: TableTypeObject): boolean {
  return table.tableType === DocumentTableType.MASTER_DATABASE_SEARCH_RESULT;
}

import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core.store';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface DocumentTableState extends EntityState<DocumentTable> {
  fetchingState: FetchingStates;
  restoringState: RestoreStates;
  documentSequencesCount: DocumentSequencesCountState;
}

export interface FetchingStates {
  [documentID: string]: FetchingState;
}

export interface FetchingState {
  fetching: boolean;
  error?: FetchingStateError;
}

export interface DocumentSequencesCountState {
  [documentID: string]: number;
}

export type FetchingStateError =
  | 'No tables exist on this document'
  | 'Failed to fetch document tables';
export type RestoreStateError =
  | 'Document table index state is absent'
  | 'Failed to restore document table';

export interface RestoreStates {
  [documentIDTableName: string]: RestoreState;
}

export interface RestoreState {
  restoring: boolean;
  restored: boolean;
  progress?: number;
  error?: RestoreStateError;
}

export interface DocumentTableStore extends AppState {
  documentTableState: DocumentTableState;
}

export function whenTablesAreFetched(documentID: string) {
  return function (observable: Observable<DocumentTableState>) {
    return observable.pipe(
      filter((state) => {
        return state.fetchingState[documentID] && !state.fetchingState[documentID].fetching;
      }),
    );
  };
}

export enum DocumentTableType {
  SEQUENCES = 'AnnotatorResultSequences',
  INEXACT_CLUSTER = 'AnnotatorResultInexactCluster',
  CODON_USAGE = 'AnnotatorResultCodonUsage',
  COMPARISON_SUMMARY = 'AnnotatorResultComparisonSummary',
  ASSAY_DATA = 'AnnotatorResultAssayData',
  CHERRY_PICKING = 'AnnotatorResultCherryPicking',
  CLUSTER_GENE = 'AnnotatorResultClusterGene',
  CLUSTERS = 'AnnotatorResultClusters',
  COMPARISON_CLUSTERS = 'AnnotatorResultComparisonClusters',
  COMPARISON_CLUSTER_GENE = 'AnnotatorResultComparisonClusterGene',
  COMPARISON_CLUSTER_LENGTH = 'AnnotatorResultComparisonClusterLength',
  MASTER_DATABASE_SEARCH_RESULT = 'MasterDatabaseSearchResult',
  MASTER_DATABASE = 'MasterDatabase',
  ANNOTATOR_RESULT_CHAIN_COMBINATIONS = 'AnnotatorResultChainCombinations',
}

import { Injectable } from '@angular/core';
import {
  DocumentTableState,
  DocumentTableStore,
  FetchingState,
  RestoreState,
  whenTablesAreFetched,
} from './document-table-state';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectDocumentTableIndexState,
  selectDocumentTables,
  selectDocumentTablesFetchingState,
  selectDocumentTableState,
  selectRestoringState,
  selectRestoringStates,
} from './document-table-state.selectors';
import {
  documentTableSelected,
  fetchDocumentTablesInBulk,
  refreshTables,
  restoreDocumentTable,
} from './document-table-state.actions';
import { delay, filter, map, tap } from 'rxjs/operators';
import {
  DocumentTable,
  tableIsQueryable,
} from '../../../../nucleus/services/documentService/types';
import { arrayToMap } from '../../../../bx-common-extensions/array';
import { validTablesForDisplay } from '../../ngs/table-type-filters';

@Injectable({
  providedIn: 'root',
})
export class DocumentTableStateService {
  documentTableState$: Observable<DocumentTableState>;

  constructor(private store: Store<DocumentTableStore>) {
    this.documentTableState$ = this.store.pipe(select(selectDocumentTableState));
  }

  /**
   * Get a table from the document table store, only if the table is queryable.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getQueryableTable(documentID: string, tableName: string): Observable<DocumentTable> {
    return this.getTable(documentID, tableName).pipe(
      filter((table) => table && tableIsQueryable(table)),
    );
  }

  /**
   * Get a table from the document table store, no matter if the table is queryable.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getTable(documentID: string, tableName: string): Observable<DocumentTable> {
    return this.documentTableState$.pipe(
      whenTablesAreFetched(documentID),
      map((state) => state.entities[documentID + tableName]),
    );
  }

  /**
   * Get a list of tables belongs to a document
   *
   * Note that if the table data isn't already populated in the store, this won't emit anything. You can ensure tables
   * are populated by calling {@link fetchTables}
   *
   * @param documentID - documentID of the table
   * */
  getTables(documentID: string): Observable<DocumentTable[]> {
    return this.store.pipe(
      select(selectDocumentTableState),
      tap((state) => {
        const maybeError = state.fetchingState[documentID]?.error;
        if (maybeError) {
          throw new Error(maybeError);
        }
      }),
      whenTablesAreFetched(documentID),
      select(selectDocumentTables(documentID)),
      map(validTablesForDisplay),
    );
  }

  /**
   * Trigger an action to populate the store with table states of all the provided document ID's
   *
   * @param documentIDs
   * */
  fetchTables(documentIDs: string[]) {
    this.store.dispatch(fetchDocumentTablesInBulk({ documentIDs }));
  }

  /**
   * Get the tables fetching state for a document. Only emit the fetching state if the document tables
   * are being fetched.
   *
   * @param documentID - documentID of the table
   * */
  getTablesFetchingState(documentID: string): Observable<FetchingState> {
    return this.store.pipe(
      select(selectDocumentTablesFetchingState(documentID)),
      filter((fetchingState) => fetchingState != null),
    );
  }

  /**
   * Get a map of tables belongs to a document.
   *
   * @param documentID - documentID of the table
   * */
  getTablesMap(documentID: string): Observable<Record<string, DocumentTable>> {
    return this.store.pipe(
      select(selectDocumentTableState),
      whenTablesAreFetched(documentID),
      select(selectDocumentTables(documentID)),
      // TODO making a map again is just inefficient
      map((tables) => arrayToMap(tables, 'name')),
    );
  }

  /**
   * Trigger a tables refresh for a given document.
   *
   * @param documentID - document to refresh.
   */
  refreshTables(documentID: string): void {
    this.store.dispatch(refreshTables({ documentID }));
  }

  /**
   * Force restore a table and return its restore state.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  forceRestoreTable(documentID: string, tableName: string): Observable<RestoreState> {
    this.store.dispatch(restoreDocumentTable({ documentID, tableName }));
    return this.getRestoreTableState(documentID, tableName).pipe(
      // TODO REMOVE THIS DELAY! `.dispatch` is somehow async?
      delay(0),
    );
  }

  /**
   * "Select" a table. This is mostly used to trigger an effect that automatically restore the selected table in the
   * background.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  selectTable(documentID: string, tableName: string): void {
    this.store.dispatch(documentTableSelected({ documentID, tableName }));
  }

  /**
   * Get restoring state of a table. Returns RestoreState regardless of whether the table existed or not.
   * If the table isn't being restored or isn't existed then its state should be { restored: false, restoring: false }
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getRestoreTableState(documentID: string, tableName: string): Observable<RestoreState> {
    return this.store.pipe(select(selectRestoringState(documentID, tableName)));
  }

  /**
   * Get table index state from the document table store.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getTableIndexState(
    documentID: string,
    tableName: string,
  ): Observable<'absent' | 'open' | 'closed' | 'archived'> {
    return this.store.pipe(select(selectDocumentTableIndexState(documentID, tableName)));
  }

  getRestoreTableStates(documentID: string): Observable<RestoreState[]> {
    return this.store.pipe(select(selectRestoringStates(documentID)));
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DocumentServiceGetTableResponse,
  DocumentServiceTableInfo,
  DocumentServiceTableInfoMap,
} from './types';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { IGridResourceResponse, MergeTableResponse } from '../models/response.model';
import { DocumentStatusKind } from '../../v2/models/activity-events/document-activity-event.model';
import { DocumentTableType } from './document-table-type';
import { NucleusDataResponseV2 } from '../../nucleus.model';
import { APP_CONFIG, AppConfig } from '../../../app/app.config';

@Injectable({
  providedIn: 'root',
})
export class DocumentServiceHttpV1 {
  private readonly endpoint: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient,
  ) {
    this.endpoint = `${this.config.nucleusApiBaseUrl}/api/document-table/v1`;
  }

  getTable(id: string, tableName: string): Observable<DocumentServiceTableInfo> {
    const target = `${this.endpoint}/documents/${id}/tables/${encodeURIComponent(tableName)}`;
    return this.httpClient
      .get<NucleusDataResponseV2<DocumentServiceTableInfo>>(target)
      .pipe(map((response) => response.data));
  }

  getTables(id: string): Observable<DocumentServiceTableInfoMap> {
    // joined=true will return joined tables information for each table.
    const target = `${this.endpoint}/documents/${id}/tables?joined=true`;
    return this.httpClient.get<DocumentServiceGetTableResponse>(target).pipe(pluck('data'));
  }

  getJoins(id: string, tableName: string): Observable<DocumentServiceJoinsResponse> {
    const target = `${this.endpoint}/documents/${id}/tables/${encodeURIComponent(tableName)}/joins`;
    return this.httpClient.get<DocumentServiceJoinsResponse>(target);
  }

  restoreTable(id: string, tableName: string): Observable<any> {
    const target = `${this.endpoint}/documents/${id}/tables/${encodeURIComponent(
      tableName,
    )}/index/restore`;
    return this.httpClient.post(target, null);
  }

  queryTable(
    id: string,
    tableName: string,
    options?: DocumentServiceDocumentQuery,
  ): Observable<IGridResourceResponse<any>> {
    const target = `${this.endpoint}/documents/${id}/tables/${encodeURIComponent(tableName)}/query`;

    const body = {
      fields: options.fields && options.fields.length ? options.fields : [],
      where: options.where ? options.where.trim() : '',
      orderBy: options.orderBy ?? [],
      limit: options.limit ?? 1000,
      offset: options.offset ?? 0,
    };

    return this.httpClient.post<DocumentTableServiceQueryResponse>(target, body).pipe(
      map((response) => {
        return {
          data: response.data,
          metadata: {
            offset: response.metadata.page.offset,
            limit: response.metadata.page.limit,
            total: response.metadata.page.total,
          },
        };
      }),
    );
  }

  queryTableAggregate(
    id: string,
    tableName: string,
    options?: AggregatedDocumentQuery,
  ): Observable<AggregatedDocumentResponse> {
    const target = `${this.endpoint}/documents/${id}/tables/${encodeURIComponent(
      tableName,
    )}/query/aggregated`;

    const body = {
      fields: options.fields && options.fields.length ? options.fields : [],
      where: options.where ? options.where.trim() : '',
      orderBy: options.orderBy || [],
      limit: options.limit || 1000,
      offset: options.offset || 0,
      groupBy: options.groupBy && options.groupBy.length ? options.groupBy : [], // What are you even doing if your aggregation query has no groupBy??
    };

    return this.httpClient.post<AggregatedDocumentResponse>(target, body);
  }

  queryTableSearch(
    orgID: string,
    queryString: string,
    sort: string,
    limit: number = 1000,
  ): Observable<IGridResourceResponse<any>> {
    // TODO This endpoint doesn't actually support sort yet, this is just wishful thinking.
    const sortSuffix = sort ? `&sort=${sort}` : '';
    const target = `${this.endpoint}/organizations/${orgID}/search?page.limit=${limit}&page.includeTotal=true${sortSuffix}`;
    const body = {
      query: `Heavy\\ CDR3.keyword:"${queryString}" OR Light\\ CDR3.keyword:"${queryString}" OR Heavy-Light\\ CDR3.keyword:"${queryString}"`,
    };
    return this.httpClient.post<DocumentTableServiceQueryResponse>(encodeURI(target), body).pipe(
      map((response) => {
        return {
          data: response.data,
          metadata: {
            offset: response.metadata.page.offset,
            limit: response.metadata.page.limit,
            total: response.metadata.page.total,
          },
        };
      }),
    );
  }

  /**
   * Joins a table onto another table of a document.
   * @see https://bitbucket.org/biomatters/nucleus-services/src/5bd61e994aef2c40dad3368f2c8be0cc6a72d9d9/docs/document-table-service.md
   *
   * @param {string} documentId
   * @param parameters all are required.
   */
  joinTable(documentId: string, parameters: DocumentServiceJoinTableParameters) {
    const target = `${this.endpoint}/documents/${documentId}/tables/${encodeURIComponent(
      parameters.baseTableName,
    )}/joins/${encodeURIComponent(parameters.joinedTableName)}`;

    const body = {
      baseColumn: parameters.baseColumnId,
      joinedColumn: parameters.joinedColumnId,
      comparison: {
        kind: 'Natural',
        ignoreLeadingSpaces: true,
        ignoreLeadingZeros: true,
        caseSensitive: false,
      },
    };

    return this.httpClient.put<IGridResourceResponse<any>>(target, body);
  }

  mergeTable(
    documentId: string,
    parameters: DocumentServiceMergeTableParameters,
  ): Observable<MergeTableResponse> {
    const target = `${this.endpoint}/documents/${documentId}/tables/${encodeURIComponent(
      parameters.baseTableName,
    )}/merge`;

    const body = {
      dataSource: {
        kind: 'CsvDataSource',
        fileLocation: {
          kind: 'NucleusBlob',
          documentID: documentId,
          blobName: parameters.blobName,
        },
      },
      join: {
        baseColumn: parameters.baseColumnId,
        joinedColumn: parameters.joinedColumnId,
        comparison: {
          kind: 'Natural',
          ignoreLeadingSpaces: true,
          ignoreLeadingZeros: true,
          caseSensitive: false,
        },
      },
      targetColumnGroup: parameters.targetColumnGroup,
      mergeConflictResolutionStrategy: 'overwriteAllCells',
    };

    return this.httpClient.post<MergeTableResponse>(target, body);
  }

  /**
   * Returns a jobID;
   */
  addTable(
    documentID: string,
    tableName: string,
    tableType: DocumentTableType,
    displayName: string,
    blobName: string,
  ): Observable<string> {
    const target = `${this.endpoint}/documents/${documentID}/tables/${encodeURIComponent(
      tableName,
    )}`;

    const body = {
      kind: 'Inferred',
      tableType: tableType,
      displayName: displayName,
      fileLocation: {
        kind: 'NucleusBlob',
        documentID,
        blobName,
      },
    };

    return this.httpClient
      .put<IGridResourceResponse<any>>(target, body)
      .pipe(map((response: any) => response.data.jobID));
  }

  updateRows(documentId: string, tableName: string, body: UpdateRowsBody): Observable<string> {
    // Encode the URI to handle columnName and value possibly having characters that need to be encoded for URIs (e.g. spaces).
    const target = `${this.endpoint}/documents/${documentId}/tables/${encodeURIComponent(
      tableName,
    )}/rows`;

    return this.httpClient
      .patch<NucleusDataResponseV2<{ kind: string }>>(target, body)
      .pipe(pluck('data'), pluck('kind'));
  }

  getTableStatus(
    documentId: string,
    tableName: string,
  ): Observable<DocumentServiceTableStatusResponse> {
    const target = `${this.endpoint}/documents/${documentId}/tables/${encodeURIComponent(
      tableName,
    )}/status`;

    return this.httpClient.get<DocumentServiceTableStatusResponse>(target);
  }

  deleteTable(documentId: string, tableName: string): Observable<any> {
    return this.httpClient.delete(
      `${this.endpoint}/documents/${documentId}/tables/${encodeURIComponent(tableName)}`,
    );
  }

  splitJoin(documentId: string, tableName: string, joinedTableName: string): Observable<any> {
    return this.httpClient.delete(
      `${this.endpoint}/documents/${documentId}/tables/${encodeURIComponent(
        tableName,
      )}/joins/${encodeURIComponent(joinedTableName)}`,
    );
  }

  deleteColumnGroup(
    documentId: string,
    tableName: string,
    columnGroup: string,
    dropColumns: boolean,
  ): Observable<any> {
    return this.httpClient.delete(
      `${this.endpoint}/documents/${documentId}/tables/${tableName}/column-groups/${columnGroup}?dropColumns=${dropColumns}`,
    );
  }
}

export interface UpdateRowsBody {
  [key: string]: {
    columns: {
      [key: string]: RowsDeltaUpdate | RowsValueUpdate;
    };
  };
}

export interface RowsDeltaUpdate {
  add?: string[] | number[];
  subtract?: string[] | number[];
}

export interface RowsValueUpdate {
  value: string | string[] | number | number[];
}

export interface DocumentServiceJoinTableParameters {
  baseTableName: string;
  joinedTableName: string;
  baseColumnId: string;
  joinedColumnId: string;
}

export interface DocumentServiceMergeTableParameters {
  baseTableName: string;
  blobName: string;
  baseColumnId: string;
  joinedColumnId: string;
  targetColumnGroup?: string;
}

/**
 * Standard one that we query to show results in the UI
 */
export interface DocumentServiceDocumentQuery {
  fields?: string[];
  where?: string;
  // Not sure what type this is yet.
  orderBy?: OrderBy[];
  limit?: number;
  offset?: number;
}

export interface AggregatedDocumentQuery extends DocumentServiceDocumentQuery {
  // fields property can contain aggregations such as "sum('Heavy CDR3 Length') sum_val" or
  // "COUNT('ID') ident", where "ID" is the target field name and ident is the name of the returned property.
  // Only properties specified in the groupBy array can be present in fields without an aggregation function.
  groupBy?: string[];
}

/**
 * Used for export.
 * This looks similar to DocumentServiceDocumentQuery but should not extend it; that's misleading.
 */
export interface CursorDocumentQuery {
  fields: string[];
  where: string;
  orderBy: OrderBy[];
  cursorSize: number;
}

/**
 * Used by the document service for export / queries.
 */
export interface OrderBy {
  kind: 'ascending' | 'descending';
  field: string;
}

export interface DocumentTableServiceQueryResponse {
  data: any[];
  metadata: {
    page: {
      offset: number;
      size: number;
      limit: number;
      total: number;
    };
  };
}

export interface DocumentServiceJoinsResponse {
  // Map
  data: any;
  /**
   * {
    "data": {
        "ASSAY_DATA_ASSAY4.CSV": {
            "baseColumn": "ID",
            "joinedColumn": "a",
            "comparison": {
                "kind": "Natural",
                "ignoreLeadingSpaces": true,
                "ignoreLeadingZeros": true,
                "caseSensitive": false
            },
            "joinedAt": "2018-09-13T21:15:18.706Z"
        }
    }
}
   */
}

export interface DocumentServiceTableStatusResponse {
  data: {
    kind: DocumentStatusKind;
  };
}

export interface AggregatedDocumentResponse {
  data: {
    Length: {
      buckets: Bucket[];
      doc_count_error_upper_bound: number;
      sum_other_doc_count: number;
    };
  };
}

export interface Bucket {
  key: number;
  doc_count: number;
}

import { DocumentTableStore } from './document-table-state';
import { createSelector } from '@ngrx/store';
import { adapter, generateDocumentTableStateID } from './document-table-state.reducer';

export const selectDocumentTableState = (state: DocumentTableStore) => {
  return state.documentTableState;
};

export const selectDocumentTableRestoringStates = createSelector(
  selectDocumentTableState,
  (state) => state.restoringState,
);

export const selectDocumentTables = (documentID: string) =>
  createSelector(
    adapter.getSelectors().selectAll,
    // TODO Improve this normalization, I shouldn't need to use a filter.
    (tables) => tables.filter((table) => table.documentID === documentID),
  );

export const selectDocumentTable = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTableState,
    (state) => state.entities[generateDocumentTableStateID(documentID, tableName)],
  );

export const selectRestoringState = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTableRestoringStates,
    (restoringState) =>
      restoringState[generateDocumentTableStateID(documentID, tableName)] ?? {
        restored: false,
        restoring: false,
      },
  );

export const selectDocumentTableIndexState = (documentID: string, tableName: string) =>
  createSelector(selectDocumentTable(documentID, tableName), (state) => state.indexState);

export const selectRestoringStates = (documentID: string) =>
  createSelector(selectDocumentTableRestoringStates, (restoringState) =>
    Object.keys(restoringState)
      .filter((stateID) => stateID.startsWith(documentID))
      .map((stateID) => restoringState[stateID]),
  );

export const selectDocumentTablesFetchingState = (documentID: string) =>
  createSelector(selectDocumentTableState, (state) => state.fetchingState[documentID]);

export const selectDocumentTableSequencesCount = (documentID: string) =>
  createSelector(selectDocumentTableState, (state) => state.documentSequencesCount[documentID]);

export const selectDocumentTableRestoringState = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTableRestoringStates,
    (state) => state[generateDocumentTableStateID(documentID, tableName)],
  );

export const selectDocumentTableNotRestoring = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTable(documentID, tableName),
    selectDocumentTableRestoringState(documentID, tableName),
    (documentTable, restoringState) => {
      if (documentTable && !restoringState?.restoring) {
        return documentTable;
      }
    },
  );

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DocumentServiceHttpV1 } from '../../../nucleus/services/documentService/document-service.v1.http';
import { DialogService } from '../../shared/dialog/dialog.service';
import { DocumentTableStateService } from './document-table-state/document-table-state.service';
import { DocumentTable } from '../../../nucleus/services/documentService/types';

@Injectable({
  providedIn: 'root',
})
export class DocumentTableService {
  constructor(
    private documentServiceHttpV1: DocumentServiceHttpV1,
    private documentTableStateService: DocumentTableStateService,
    private dialogService: DialogService,
  ) {}

  static splitAssayColumnKey(
    assayColumnKey: string,
  ): { tableName: string; columnName: string; parents: string[] } | undefined {
    const split: string[] = assayColumnKey.split(':');
    if (split.length === 1) {
      return undefined;
    } else {
      return {
        columnName: split.pop(),
        tableName: split.pop(),
        parents: split,
      };
    }
  }

  /**
   * Removes Joined Table from the base table (DOCUMENT_TABLE_ALL_SEQUENCES).
   * NOTE: The application only supports joining tables to the DOCUMENT_TABLE_ALL_SEQUENCES table. Modify
   * this method to take in an arbitrary base table when assay data is supported in other tables.
   *
   * @param documentID - document ID to remove the joined table from.
   * @param tableName - the assayID/tableName of the Table to remove from the document.
   */
  removeTable(documentID: string, tableName: string): Observable<void> {
    return of(tableName.split(':').pop()).pipe(
      switchMap((table) => this.documentServiceHttpV1.deleteTable(documentID, table)),
    );
  }

  deleteColumnGroup(
    documentID: string,
    tableName: string,
    columnGroup: string,
    dropColumns = true,
  ): Observable<void> {
    return this.documentServiceHttpV1.deleteColumnGroup(
      documentID,
      tableName,
      columnGroup,
      dropColumns,
    );
  }

  /**
   * Opens a confirmation dialog and then deletes the column group or joined table
   * and all its columns (on confirmation) before refreshing the document's table.
   *
   * @param selectedTable - the currently selected table for a document (Annotated Result, Collections).
   * @param groupID - the ID of the Group, this would be the `name` property of the DocumentTable interface.
   * @param groupDisplayName - the display name of the group, for displaying in the confirmation dialog.
   */
  removeAssayTableAction(
    selectedTable: DocumentTable,
    groupID: string,
    groupDisplayName: string,
  ): Observable<unknown> {
    const documentID = selectedTable.documentID;
    const removeAssayData$ = selectedTable.columnGroups[groupID]
      ? this.deleteColumnGroup(documentID, selectedTable.name, groupID, true)
      : this.removeTable(documentID, groupID);
    const confirmationObservable: Observable<any> = removeAssayData$.pipe(
      tap(() => this.documentTableStateService.refreshTables(documentID)),
    );

    return this.dialogService.showConfirmationDialog$({
      title: `Permanently delete Assay Data (${groupDisplayName})?`,
      confirmationButtonText: 'Delete',
      confirmationButtonColor: 'danger',
      confirmationObservable: confirmationObservable,
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Emits/Tracks the status of a assay data being added.
 * Used within AssayDataProgressBarComponent.
 */
@Injectable({
  providedIn: 'root',
})
export class AssayDataLoggerService implements OnDestroy {
  status$: BehaviorSubject<Status>;
  private startDate: Date;

  constructor() {
    this.status$ = new BehaviorSubject<Status>({ progress: 0, error: false });
  }

  ngOnDestroy(): void {
    this.status$.complete();
  }

  start() {
    this.startDate = new Date();
    this.setProgress(2, 'Uploading data');
  }

  setProgress(progress: number, message: string) {
    this.status$.next({ progress, message, error: false });
  }

  stop() {
    this.reset();
  }

  reset() {
    this.status$.next({ progress: 0, error: false });
  }

  setError(errorMessage: string) {
    this.status$.next({ progress: 0, message: errorMessage, error: true });
  }
}

export interface Status {
  progress: number;
  message?: string;
  error: boolean;
}

import { Injectable } from '@angular/core';
import {
  DocumentServiceDocumentQuery,
  DocumentServiceHttpV1,
  DocumentServiceJoinTableParameters,
  DocumentServiceMergeTableParameters,
  DocumentServiceTableStatusResponse,
  OrderBy,
  UpdateRowsBody,
} from './document-service.v1.http';
import { combineLatest, concat, Observable, throwError } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  mapTo,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { IGridResourceResponse, MergeTableResponse } from '../models/response.model';
import {
  DocumentServiceColumn,
  DocumentServiceColumnDataType,
  DocumentServiceTableInfo,
  DocumentServiceTableInfoMap,
  DocumentTable,
} from './types';
import {
  DocumentActivityEvent,
  DocumentStatusKind,
} from '../../v2/models/activity-events/document-activity-event.model';
import { DocumentActivityEventKind } from '../../v2/models/activity-events/activity-event-kind.model';
import { AssayDataLoggerService } from '../../../app/core/assay-data-v2/assay-data-logger.service';
import { SortModel } from '../../../app/features/grid/grid.interfaces';
import { ColDef } from '@ag-grid-community/core';
import { GridStateService } from '../../../app/core/grid-state/grid-state.service';
import { generateJoinedColumnID } from '../../../app/core/document-service/document-service.functions';
import { BxHttpError } from '../../../app/core/BxHttpError';
import { DocumentTableService } from '../../../app/core/document-table-service/document-table.service';
import { ActivityStreamService } from '../../../app/core/activity/activity-stream.service';
import { DocumentTableType } from './document-table-type';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private assayDataLogger: AssayDataLoggerService,
    private activityStreamService: ActivityStreamService,
    private documentServiceHttpV1: DocumentServiceHttpV1,
    private gridStateService: GridStateService,
  ) {}

  /**
   *  TODO Don't hardcode these strings; use an enum instead, e.g. "DocumentTable.ALL_SEQUENCES".
   */
  queryTable(
    documentId: string,
    documentTable: string,
    options: DocumentServiceDocumentQuery = {},
  ): Observable<IGridResourceResponse<any>> {
    return this.documentServiceHttpV1.queryTable(documentId, documentTable, options);
  }

  getTable(id: string, tableName: string): Observable<DocumentServiceTableInfo> {
    return this.documentServiceHttpV1.getTable(id, tableName);
  }

  getTables(documentId: string): Observable<DocumentTable[]> {
    return this.documentServiceHttpV1.getTables(documentId).pipe(
      map((tableMap) =>
        Object.keys(tableMap).map((key) => ({
          ...tableMap[key],
          name: key,
          documentID: documentId,
        })),
      ),
    );
  }

  /**
   * Restores an archived table of a document.
   * Can be a long running request so may take a while.
   * Still only emits once and completes thereafter (One-time observable).
   *
   * NOTE: It will return true if successful, otherwise it will throw an error.
   *
   * @param documentId - of the document that has the archived table.
   * @param table - table of the document to restore.
   */
  restoreTable(documentId: string, table: string): Observable<void> {
    return this.documentServiceHttpV1.restoreTable(documentId, table).pipe(
      catchError((error: BxHttpError) => {
        // Sometimes the server might return 409 or 400 if the table is already restoring or in an intermediate state.
        // Thus here it checks if the table is still archived and continues from there.
        if (error.status === 409 || error.status === 400) {
          return this.documentServiceHttpV1.getTable(documentId, table).pipe(
            map((newTable) => {
              if (newTable.indexState === 'archived') {
                // If still archived, then throw error to force a retry from the retryWhen operator.
                throw error;
              }
            }),
            // If table is still archived, Retry `getTable()` to check if the table is finishing restoring yet,
            // with an interval of 10 seconds.
            retryWhen((errors) => errors.pipe(delay(10000), take(50))),
          );
        } else {
          // Any other server errors should fail this stream.
          return throwError(error);
        }
      }),
    );
  }

  queryTableSearch(
    orgID: string,
    queryString: string,
    sort: string = '',
  ): Observable<IGridResourceResponse<any>> {
    return this.documentServiceHttpV1.queryTableSearch(orgID, queryString, sort).pipe(
      map((results: IGridResourceResponse<any>) => {
        results.columns = this.generateColumnsFromRowData(results.data, [
          'row_number',
          '_tableName',
          'ID',
          'Associated Sequences',
          'geneious_row_index',
          '_documentID',
          '_documentName',
          '_bxUniqueIdentifier',
          'parentID',
          'row_id',
          'rowID',
          'id',
          'documentID',
          'folderID',

          // System columns from CDR3 search results.
          '_documentTableRowNumber',
          '_globalSearchRowID',
          '_folderID',
        ]);
        return results;
      }),
    );
  }

  generateColumnsFromRowData(rows: any[], blacklist: string[]): ColDef[] {
    // TODO Maybe tidy up names here? Or use the friendly name if the server actually returns one.
    const getFriendlyName = (key: string) => key;
    const names: { [type: string]: boolean } = {};
    rows.forEach((row) =>
      Object.keys(row)
        .filter((key) => !blacklist.includes(key))
        .forEach((key) => (names[key] = true)),
    );
    return Object.keys(names).map((key) => ({ field: key, headerName: getFriendlyName(key) }));
  }

  addTable(
    documentID: string,
    tableName: string,
    tableType: DocumentTableType,
    displayName: string,
    blobName: string,
  ): Observable<string> {
    return this.documentServiceHttpV1.addTable(
      documentID,
      tableName,
      tableType,
      displayName,
      blobName,
    );
  }

  joinTable(documentId: string, parameters: DocumentServiceJoinTableParameters): Observable<any> {
    return this.documentServiceHttpV1.joinTable(documentId, parameters);
  }

  mergeTable(
    documentId: string,
    parameters: DocumentServiceMergeTableParameters,
  ): Observable<MergeTableResponse> {
    return this.documentServiceHttpV1.mergeTable(documentId, parameters);
  }

  updateRows(documentId: string, tableName: string, body: UpdateRowsBody): Observable<string> {
    return this.documentServiceHttpV1.updateRows(documentId, tableName, body);
  }

  getTableStatus(
    documentId: string,
    tableName: string,
  ): Observable<DocumentServiceTableStatusResponse> {
    return this.documentServiceHttpV1.getTableStatus(documentId, tableName);
  }

  /**
   * Add and Join table in one method.
   * Returns Observable of events for each completed stage.
   *
   * @param documentId
   * @param tableType
   * @param baseTableName base table that the new table is being joined against.
   * @param newTableName new table to be joined onto the base table.
   * @param newTableDisplayName
   * @param baseColumnId column id of the column from the base table to be matched against from the join table column.
   * @param joinedColumnId column id of the column from the join table to be matched against the base table column.
   * @param originalBaseTableName the name of the original base table because when we join table with an existing assay table
   *  the base table name became the name of the assay data table, which is not a valid table for operations like making column visible.
   */
  addAndJoinTable(
    documentId: string,
    tableType: DocumentTableType,
    baseTableName: string,
    newTableName: string,
    newTableDisplayName: string,
    baseColumnId: string,
    joinedColumnId: string,
    originalBaseTableName?: string,
  ): Observable<AddAndJoinTableEvent> {
    const listener$ = this.listenToDocumentActivity(documentId, baseTableName, newTableName);

    const addTable$ = this.addTable(
      documentId,
      newTableName,
      tableType,
      newTableDisplayName,
      newTableName,
    ).pipe(
      mapTo(AddAndJoinTableEvent.TABLE_ADDED),
      tap(() => this.assayDataLogger.setProgress(20, 'Reading your assay data')),
    );
    const listenForTableAddingFinished$ = DocumentService.listenForTableIndexCompleted(
      listener$,
      newTableName,
    ).pipe(
      mapTo(AddAndJoinTableEvent.TABLE_FINISHED_ADDING),
      tap(() => this.assayDataLogger.setProgress(30, 'Finished reading your assay data')),
    );

    const joinTable$ = this.getUpdatedJoinedColumn(documentId, newTableName, joinedColumnId).pipe(
      switchMap((newJoinedColumn) => {
        if (newJoinedColumn) {
          return this.joinTable(documentId, {
            baseTableName: baseTableName,
            joinedTableName: newTableName,
            baseColumnId,
            joinedColumnId: newJoinedColumn.name,
          }).pipe(
            // Error with just a string will trigger the default error message case (which is what we want for this).
            catchError(() => throwError('Failed to join table')),
          );
        } else {
          // This should never happen, but it could.
          // Error with just a string will trigger the default error message case (which is what we want for this).
          return throwError(`Couldn't find join column`);
        }
      }),
      mapTo(AddAndJoinTableEvent.TABLE_JOIN_STARTED),
      tap(() => this.assayDataLogger.setProgress(70, `Merging the two datasets`)),
    );

    const listenForTableJoinFinished$ = DocumentService.listenForTableIndexCompleted(
      listener$,
      baseTableName,
    ).pipe(mapTo(AddAndJoinTableEvent.TABLE_JOIN_PROCESSED));

    const setColumnsAsVisible$ = this.documentServiceHttpV1.getTable(documentId, newTableName).pipe(
      tap((table) =>
        this.gridStateService.setColumnsVisible(
          originalBaseTableName ?? baseTableName,
          table.columns.map((col) => generateJoinedColumnID(newTableName, col.name)),
        ),
      ),
      mapTo(AddAndJoinTableEvent.TABLE_JOIN_COMPLETE),
      tap(() => this.assayDataLogger.setProgress(100, `Done`)),
    );

    // We need to merge the listening and updating events because otherwise we get race conditions as updating
    // triggers a table reindex which could emit an event before we've started listening for them.
    const addTableAndListen$ = combineLatest([addTable$, listenForTableAddingFinished$]).pipe(
      map(([x, y]) => y),
    );

    const joinTableAndListen$ = combineLatest([joinTable$, listenForTableJoinFinished$]).pipe(
      map(([x, y]) => y),
    );

    return concat(
      // Add the table to the Document Table Service.
      addTableAndListen$,
      // Join the added table to the base table.
      joinTableAndListen$,
      // Set the new assay columns in the related grid state to visible so the user can see their new columns.
      setColumnsAsVisible$,
    ).pipe(
      catchError((e) => {
        if (e && e.error && (e.error.error || e.error.detail)) {
          this.assayDataLogger.setError(
            this.replaceNucMessageWithFriendly(e.error.error || e.error.detail),
          );
        } else {
          this.assayDataLogger.setError(`Something went wrong - please try again later.
            If you continue to have problems, contact support.`);
        }

        // Important to rethrow so a) subscribe isn't called and b) so something shows up in the developer tools console.
        throw e;
      }),
    );
  }

  mergeTableWithProgress(
    documentId: string,
    baseTableName: string,
    blobName: string,
    targetColumnGroup: string,
    baseColumnId: string,
    joinedColumnId: string,
  ): Observable<AddAndJoinTableEvent> {
    const listener$ = this.listenToDocumentActivity(documentId, baseTableName);

    this.assayDataLogger.setProgress(30, 'Finished reading your assay data');

    const mergeTable$ = this.mergeTable(documentId, {
      baseTableName,
      blobName,
      baseColumnId,
      joinedColumnId,
      targetColumnGroup,
    }).pipe(
      mapTo(AddAndJoinTableEvent.TABLE_JOIN_STARTED),
      tap(() => this.assayDataLogger.setProgress(70, `Merging the two datasets`)),
    );

    const listenForTableMergeFinished$ = DocumentService.listenForTableIndexCompleted(
      listener$,
      baseTableName,
    ).pipe(
      mapTo(AddAndJoinTableEvent.TABLE_JOIN_COMPLETE),
      tap(() => this.assayDataLogger.setProgress(100, `Done`)),
    );

    return concat(
      // Merge the assay data to the base table.
      mergeTable$,
      // Listen for the table to finish being joined.
      listenForTableMergeFinished$,
    ).pipe(
      catchError((e) => {
        if (e && e.error && (e.error.error || e.error.detail)) {
          this.assayDataLogger.setError(
            this.replaceNucMessageWithFriendly(e.error.error || e.error.detail),
          );
        } else {
          this.assayDataLogger.setError(`Something went wrong - please try again later.
            If you continue to have problems, contact support.`);
        }

        // Important to rethrow so a) subscribe isn't called and b) so something shows up in the developer tools console.
        throw e;
      }),
    );
  }

  /**
   * We need to first retrieve the newly added assay data table from nucleus so we can get the parsed join column id.
   * Nucleus replaces invalid characters with underscores and thus the joined column id could be different after the table has been added.
   * e.g. a joinedColumnId of `id:1` would be `id_1` when parsed by Nucleus in addTable().
   * We want to use the sanitised column id when calling the join table API next.
   */
  private getUpdatedJoinedColumn(
    documentId: string,
    tableName: string,
    joinedColumnId: string,
  ): Observable<DocumentServiceColumn | undefined> {
    return this.getTable(documentId, tableName).pipe(
      map((table) => {
        let newJoinedColumnId;
        // Note the order of this property check is important. `displayName` should have never have to be checked,
        // but it's a backup just in case.
        for (const property of [
          'name',
          'originalName',
          'displayName',
        ] as (keyof DocumentServiceColumn)[]) {
          newJoinedColumnId = table.columns.find((column) => column[property] === joinedColumnId);
          if (newJoinedColumnId) {
            return newJoinedColumnId;
          }
        }
      }),
    );
  }

  private listenToDocumentActivity(
    documentID: string,
    ...tables: string[]
  ): Observable<DocumentActivityEvent> {
    return this.activityStreamService.listenToDocumentActivity(documentID).pipe(
      tap((activity) => {
        if (
          activity.event.kind === DocumentActivityEventKind.TABLE_STATUS_UPDATED &&
          activity.event.status.kind === DocumentStatusKind.ERROR &&
          tables.includes(activity.event.tableName)
        ) {
          throw new Error();
        }
      }),
    );
  }

  private static listenForTableIndexCompleted(
    listener$: Observable<DocumentActivityEvent>,
    tableName: string,
  ): Observable<DocumentActivityEvent> {
    return listener$.pipe(
      filter(
        (message) =>
          message.event.kind === DocumentActivityEventKind.TABLE_INDEX_COMPLETED &&
          message.event.tableName === tableName,
      ),
      first(),
    );
  }

  private replaceNucMessageWithFriendly(message: string) {
    // TODO Add more cases as we encounter them.
    return message.includes('already contains the table')
      ? 'Cannot add assay data. The group name you entered has already been added to this document.'
      : message;
  }
}

function fullName(sortDirection: 'asc' | 'desc'): 'ascending' | 'descending' {
  return sortDirection === 'asc' ? 'ascending' : 'descending';
}

export function sortModelToOrderBy(sortModel: SortModel[]): OrderBy[] {
  return sortModel.map((sort) => ({ kind: fullName(sort.sort), field: sort.colId }));
}

export function orderByToSortModel(orderBy: OrderBy[]): SortModel[] {
  return orderBy.map((order) => ({
    sort: order.kind === 'ascending' ? 'asc' : 'desc',
    colId: order.field,
  }));
}

/**
 * Modifies the sort model to work with our document service backend
 *
 * This service is backed by elasticsearch which stores data differently depending on exactly what it is to facilitate fast retrieval at
 * read time. If the column is of string data type, then `.keyword` needs to be appended to the field. For all other data types no prefix
 * is necessary. This may change when we support natural sort.
 *
 * @param sortModel from ag-grid.
 * @param documentTable that the table is currently selected on.
 * @param allTablesInfo Map of all tables information for a document table.
 */
export function orderByType(
  sortModel: SortModel[],
  documentTable: string,
  allTablesInfo: DocumentServiceTableInfoMap,
): OrderBy[] {
  const orderBy: OrderBy[] = [];
  sortModelToOrderBy(sortModel).forEach((sort) => {
    const tableColumn = getColumnInfoFromColumnID(sort.field, documentTable, allTablesInfo);
    if (tableColumn) orderBy.push(applyKeywordToSortedColumn(sort, tableColumn));
  });
  return orderBy;
}

/**
 * Get Column information from a given column field.
 * e.g. `ASSAY_DATA:id` will give the `id` column info from the joined table `ASSAY_DATA`.
 *
 * If column is not found, it will return undefined.
 *
 * @param field
 * @param documentTable
 * @param allTablesInfo
 */
export function getColumnInfoFromColumnID(
  field: string,
  documentTable: string,
  allTablesInfo: DocumentServiceTableInfoMap,
): DocumentServiceColumn | undefined {
  // Colon char means it must be a joined table.
  if (field.includes(':')) {
    const splitAssayKey = DocumentTableService.splitAssayColumnKey(field);
    const joinedTableName = splitAssayKey.tableName;
    const columnName = splitAssayKey.columnName;

    const tableInfo =
      allTablesInfo[Object.keys(allTablesInfo).find((tableName) => tableName === joinedTableName)];

    // If assay data column exists, then find the valid column...
    // otherwise return undefined;
    if (tableInfo) {
      return tableInfo.columns.find((col) => col.name === columnName);
    } else {
      return undefined;
    }
  } else {
    // Otherwise it must be in the current document table.
    const tableInfo = allTablesInfo[documentTable];
    return tableInfo.columns.find((col) => col.name === field);
  }
}

/**
 * Sanitizes given name into a valid name for saving as a key in the DocumentTableService.
 * Table and Column names cannot have invalid chars thus those characters are replaced with underscores.
 *
 * @param name given table/column name (usually the display name) to be parsed into a valid name key.
 */
export function sanitizeDTSTableOrColumnName(name: string): string {
  const INVALID_TABLE_AND_COLUMN_REGEX = new RegExp('[:.`\t\n\x0B\f\r]', 'g');
  return name.replace(INVALID_TABLE_AND_COLUMN_REGEX, '_').trim();
}

/**
 * Apply `.keyword` to sorted column of dataType String or Array.
 *
 * @param sort
 * @param tableColumn
 */
export function applyKeywordToSortedColumn(sort: OrderBy, tableColumn: DocumentServiceColumn) {
  if (
    tableColumn &&
    (tableColumn.dataType.kind === DocumentServiceColumnDataType.String ||
      tableColumn.dataType.kind === DocumentServiceColumnDataType.Array ||
      tableColumn.dataType.kind === DocumentServiceColumnDataType.ListSet)
  ) {
    return { kind: sort.kind, field: sort.field + '.keyword' };
  } else {
    return { kind: sort.kind, field: sort.field };
  }
}

export enum AddAndJoinTableEvent {
  TABLE_ADDED = 'Table Added',
  TABLE_FINISHED_ADDING = 'Table Finished Adding',
  TABLE_JOIN_STARTED = 'Table Join Started',
  TABLE_JOIN_PROCESSED = 'Table Join Processed',
  TABLE_JOIN_COMPLETE = 'Table Join Complete',
}

import { Activity } from './activity.model';
import { ActivityKind } from './activity-kind.model';
import { DocumentActivityEventKind } from './activity-event-kind.model';
import { BulkRowUpdate } from '@geneious/nucleus-api-client';

export interface DocumentActivityEvent extends Activity {
  kind: ActivityKind.DOCUMENT;
  event: DocumentEventType;
}

export type DocumentEventType =
  | DocumentCreatedEvent
  | DocumentUpdatedEvent
  | DocumentDeletedEvent
  | DocumentTableReIndexStartedEvent
  | DocumentTableIndexCompletedEvent
  | DocumentTableDefinedEvent
  | DocumentTableInferredTableAddedEvent
  | DocumentTableDeletedEvent
  | DocumentTableColumnAddedEvent
  | DocumentTableColumnDeletedEvent
  | DocumentTableTableJoinedEvent
  | DocumentTableTableSplitEvent
  | DocumentTableCellValueReplacedEvent
  | DocumentTableStatusUpdatedEvent
  | DocumentTableBulkRowUpdateEvent
  | DocumentTableBulkRowUpdateCompletedEvent;

export interface DocumentEvent {
  dateTime: string;
  kind: DocumentActivityEventKind;
}

export interface DocumentEventTable {
  displayName: string;
  fileLocation: {
    blobName: string;
    documentID: string;
    kind: string;
  };
}

export enum DocumentStatusKind {
  IDLE = 'Idle',
  LOADING = 'Loading',
  INDEXING = 'Indexing',
  UPDATING = 'Updating',
  ERROR = 'Error',
}

export interface DocumentStatus {
  jobID?: string;
  kind?: DocumentStatusKind;
  repeat?: boolean;
  previous?: {
    jobID: string;
    kind: DocumentStatusKind;
  };
}

export interface DocumentCellValue {
  value: unknown;
}

export interface DocumentIdentifier {
  documentID: string;
  organizationID: string;
}

export interface DocumentCreatedEvent extends DocumentEvent {
  tables: { [type: string]: any };
  kind: DocumentActivityEventKind.DOCUMENT_CREATED;
}

export interface DocumentUpdatedEvent extends DocumentEvent {
  documentID: string;
  name: string;
  parentFolderID: string;
  previousParentFolderID: string;
  kind: DocumentActivityEventKind.DOCUMENT_UPDATED;
}

export interface DocumentDeletedEvent extends DocumentEvent {
  kind: DocumentActivityEventKind.DOCUMENT_DELETED;
}

export interface DocumentTableReIndexStartedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.RE_INDEX_STARTED;
  tableName: string;
  status: DocumentStatus;
}

export interface DocumentTableIndexCompletedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.TABLE_INDEX_COMPLETED;
  tableName: string;
}

export interface DocumentTableDefinedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.TABLE_DEFINED;
  tableName: string;
  createdAt: string;
  table: DocumentEventTable;
  status: DocumentStatus;
}

export interface DocumentTableInferredTableAddedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.INFERRED_TABLE_ADDED;
  tableName: string;
  table: DocumentEventTable;
}

export interface DocumentTableDeletedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.TABLE_DELETED;
  tableName: string;
}

export interface DocumentTableColumnAddedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.COLUMN_ADDED;
  tableName: string;
  columnName: string;
}

export interface DocumentTableColumnDeletedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.COLUMN_DELETED;
  tableName: string;
  columnName: string;
}

export interface DocumentTableTableJoinedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.TABLE_JOINED;
  tableName: string;
  joinedTableName: string;
  status: DocumentStatus;
}

export interface DocumentTableTableSplitEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.TABLE_SPLIT;
  tableName: string;
  joinedTableName: string;
  status: DocumentStatus;
}

export interface DocumentTableCellValueReplacedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.CELL_VALUE_REPLACED;
  tableName: string;
  rowNumber: number;
  columnName: string;
  value: DocumentCellValue;
}

export interface DocumentTableStatusUpdatedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.TABLE_STATUS_UPDATED;
  tableName: string;
  status: DocumentStatus;
}

export interface DocumentTableBulkRowUpdateEvent extends DocumentEvent, BulkRowUpdate {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.BULK_ROW_UPDATE;
  tableName: string;
}

export interface DocumentTableBulkRowUpdateCompletedEvent extends DocumentEvent {
  documentID: DocumentIdentifier;
  kind: DocumentActivityEventKind.BULK_ROW_UPDATE_COMPLETED;
  tableName: string;
}

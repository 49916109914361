/**
 * In order for joined columns to guarantee on being unique, the column id is pre-pended with the table name via a colon as a delimiter.
 * Table Names are unique.
 *
 * @param tableName - string of the table name that the column is from.
 * @param colName - string of the column name.
 */
export function generateJoinedColumnID(tableName: string, colName: string): string {
  return `${tableName}:${colName}`;
}

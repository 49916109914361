import { AnnotatedPluginDocument } from '../geneious';
import { DocumentTable } from '../../../nucleus/services/documentService/types';
import { DocumentTableType } from '../../../nucleus/services/documentService/document-table-type';
import { MasterDatabaseSequencesResource } from '../master-database/master-database-search-result-viewer/master-database-sequences-resource.service';
import { ViewerPageURLSelectionState } from '../viewer-page/viewer-page.component';

export interface ViewerDocumentData {
  selection: ViewerDocumentSelection;
  isFreeOrg?: boolean;
  isAdminView?: boolean;
  isPreviewView?: boolean;
  containsComplexDocument?: boolean;
  openQueryParams?: ViewerPageURLSelectionState;
}

export interface ViewerResultData {
  selection: ViewerMultipleTableDocumentSelection;
  // TODO Find a way to remove selectedTable & filter.
  selectedTable: DocumentTable;
  filter?: string;
  resource?: any;
  columns?: ViewerDataColumn[];
  isAdminView?: boolean;
}

export interface ViewerMasterDatabaseSearchData {
  selection: ViewerMasterDatabaseSearchSelection;
  // TODO Find a way to remove selectedTable & filter.
  selectedTable: DocumentTable;
  resource: MasterDatabaseSequencesResource;
}

export interface ViewerDocumentSelection {
  rows: Array<AnnotatedPluginDocument>;
  total: number;
  totalSelected: number;
}

export interface ViewerMultipleTableDocumentSelection<> {
  selectAll: boolean;
  total: number;
  totalSelected: number;
  tableType: DocumentTableType;
  // TODO Add typing for AntibodyAnnotatorRows.
  rows: any[];
  selectedRows: any[];
  document: AnnotatedPluginDocument;
}

export interface ViewerMasterDatabaseSearchSelection {
  rows: any[];
  subTableRows: any[];
  totalSelected: number;
  tableType: DocumentTableType;
  document: AnnotatedPluginDocument;
}

export interface ViewerDataColumn {
  colID: string;
  headerName: string;
}

export function isViewerDocumentData(data: ViewerDocumentData): data is ViewerDocumentData {
  return 'selection' in data && isViewerDocumentSelection(data.selection);
}

export function isViewerDocumentSelection(selection: any): selection is ViewerDocumentSelection {
  return (
    (<ViewerDocumentSelection>selection).rows !== undefined &&
    (<ViewerMultipleTableDocumentSelection>selection).tableType === undefined
  );
}

export function isViewerResultData(
  data: ViewerResultData | ViewerMasterDatabaseSearchData,
): data is ViewerResultData {
  return 'selection' in data && isViewerMultipleTableDocumentSelection(data.selection);
}

export function isViewerMultipleTableDocumentSelection(
  selection: any,
): selection is ViewerMultipleTableDocumentSelection {
  return (<ViewerMultipleTableDocumentSelection>selection).tableType !== undefined;
}

export function isViewerMasterDatabaseSearchSelection(
  selection: any,
): selection is ViewerMasterDatabaseSearchSelection {
  return (<ViewerMasterDatabaseSearchSelection>selection).subTableRows !== undefined;
}
